import "./App.css";
import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/Resume";
import Rederict from "./components/Rederict/Rederict";
import AppPrivacyPolicy  from "./components/AppPrivacyPolicy/AppPrivacyPolicy";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "./components/ScrollToTop";
import AppTermsConditions from "./components/AppTermsConditions/AppTermsConditions";


function App() {
  const [load, upadateLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      upadateLoad(false);
    }, 1200);
  }, []);
  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/home" exact component={Home} />
          <Route path="/project" component={Projects} />
          <Route path="/about" component={About} />
          <Route path="/resume" component={Resume} />
          <Route path="/rederict" component={Rederict} />
          <Route path="/app-privacy-policy" component={AppPrivacyPolicy}/>
          <Route path="/app-terms-conditions" component={AppTermsConditions}/>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
