import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import BlogCard from "./BlogsCards";
import Particle from "../Particle";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";

import tabuSun from "../../Assets/Projects/tabusun.PNG";
import codinggame from "../../Assets/Projects/codinggame.jpg";
import devproductivity from "../../Assets/Projects/devproductivity.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      {/* <Particle /> */}
      <Container>
        <h1 className="project-heading">
          My Coding <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are the projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "20px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath="https://play-lh.googleusercontent.com/r4aktNFoFT9h6FplHm7yVNxA6qEK9A-MJnHk6Zc5zEWDEkuwYZviqzZloYXhhcI3TQ"
              isBlog={false}
              title="Türk Telekom - Mobil İşlemler"
              description="I played a significant role in advancing the Android development of the Turkish Telekom application, a cornerstone tool for telecom operators with a daily user engagement surpassing thousands. My contributions went beyond standard coding, encompassing the enhancement and expansion of the application's features. I take pride in delivering impactful results, and I am confident that my work reflects my dedication to excellence."
              link="https://play.google.com/store/apps/details?id=com.tmob.AveaOIM"
            />
          </Col>
          <Col md={4} className="TabuSun">
            <ProjectCard
              imgPath={tabuSun}
              isBlog={false}
              title="TabuSun-Tabu Bil Bakalım"
              description="Explore my live application on the Google Play Store, boasting over 150,000 downloads and acquiring more than 1,000 active users daily. With a remarkable crash rate consistently exceeding 99% on Crashlytics, the app promises a seamless experience. Feel free to navigate to the app by clicking on the 'View Project' button."
              link="https://play.google.com/store/apps/details?id=com.autumnsun.tabu"
            />
          </Col>
          </Row>
       {/*  <h1 className="project-heading">
          My Favorite <strong className="purple">Instagram </strong> Posts
        </h1>
        <p style={{ color: "white" }}>Do give a read to some of my posts</p>
        <Row style={{ justifyContent: "center" }}>
          <Col md={4} className="blog-card">
            <BlogCard
              imgPath={codinggame}
              link="https://www.instagram.com/p/CNS7_wmIcLj/"
              title="Coding: A Long Game"
              site=""
            />
          </Col>
          <Col md={4} className="blog-card">
            <BlogCard
              imgPath={devproductivity}
              link="https://www.instagram.com/p/CNDSdHaIv0e/"
              title="Can developer productivity be measured?"
              site=""
            />
          </Col>
        </Row> */}
      </Container>
    </Container>
  );
}

export default Projects;
