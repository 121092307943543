import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../style.css";
import "./Redirect.css";
import "bootstrap/dist/css/bootstrap.min.css";


function Rederict() {

  function redirectToPlayStore(appUrl, fallbackUrl) {
    // Bir link öğesi oluşturarak yönlendirme yap
    const link = document.createElement('a');
    link.href = appUrl;
    link.style.display = 'none'; // Linki görünmez yap
    document.body.appendChild(link); // Linki DOM'a ekle
    link.click(); // Linke tıklamayı tetikle
    document.body.removeChild(link); // Linki DOM'dan kaldır

    // Eğer Play Store yönlendirmesi başarısız olursa, 2 saniye sonra fallback URL'sine yönlendirme yap
    setTimeout(function() {
        window.location.href = fallbackUrl;
    }, 2000);
}

// Sayfa yüklendiğinde çalıştırılan fonksiyon
window.onload = function() {
    // Loading ekranını gizle
    document.getElementById('loading').classList.add('hidden');

    // Play Store'a yönlendirme denemesi yap
    redirectToPlayStore('market://details?id=com.autumnsun.tabu', 'https://fatihkurcenli.com/resume');
};

  return (
    <>
    <Container id="loading">
    </Container>
    <Container id="content" href="">
        
    </Container>
    </>
  );
}

export default Rederict;