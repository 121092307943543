import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Resumecontent from "./ResumeContent";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";

function Resume() {
  function zamanFarkiniHesapla(tarih1, tarih2) {
    // Tarihleri milisaniyeye çevir
    var zaman1 = new Date(tarih1).getTime();
    var zaman2 = new Date(tarih2).getTime();

    // Farkı hesapla
    var fark = Math.abs(zaman2 - zaman1);

    // Farkı yıl ve ay cinsine çevir
    var yil = Math.floor(fark / (1000 * 3600 * 24 * 365));
    var ay = Math.floor((fark % (1000 * 3600 * 24 * 365)) / (1000 * 3600 * 24 * 30)) + 1;


    return { yil: yil, ay: ay };
  }

  // Örnek kullanım
  var bugun = new Date();
  var belirliTarih = new Date('2022-02-01');
  var zamanFarki = zamanFarkiniHesapla(bugun, belirliTarih);
  console.log("Bugünden belirtilen tarihe kadar olan zaman: " + zamanFarki.yil + " yıl " + zamanFarki.ay + " ay");

  return (
    <Container fluid className="resume-section">
     {/* <Particle /> */}
      <Container>
        <Row className="resume">
          <Col md={6} className="resume-left">
            <h3 className="resume-title">Experience</h3>
            <Resumecontent
              title="Android Developer - Innova (Bilişim)"
              date={`March 2021 - Now  ${zamanFarki.yil} Yıl ${zamanFarki.ay} Ay`}
              location="Istanbul - Turkey"
              content={[
                "Turk Telekom Android Online Application.",
                "UX/UI design of the interfaces.",
                "Developping the mobile application for patients with Kotlin - Java.",
                "Interconnecting the backend and the mobile app with APIs.",
              ]}
            />
            <Resumecontent
              title="Android Developer - Evatro (6 Months)"
              date="August 2021 - January 2022"
              location="Istanbul - Turkey"
              content={[
                "I am an Android Application Developer. I developed modern applications using Java.",
                "In addition, I developed applications using SQLite, Network(OkHttp), Thread methods third-party libraries, and android libraries.",
                "Linking the Mobile part with back-endusing APIs.",
              ]}
            />
            <Resumecontent
              title="Android Developer - Intern"
              date="February 2021 - March 2021 (1 Month)"
              location="Istanbul - Turkey"
              content={[
                "It was an internship where I learned the current architectures of the Android application and included myself in many innovations in the field of Android."
              ]}
            />
            <Resumecontent
              title="Mobile Application Developer - Intern"
              date="Agust 2021 - September 2021 (1 Month)"
              location="Istanbul - Turkey"
              content={[
                "Flutter learned and effectively coding",
              ]}
            />
          </Col>
          <Col md={6} className="resume-right">
            <h3 className="resume-title">Extracurricular Activities</h3>
            <Resumecontent
              title="GDG Istanbul 2023"
              content={["I attended the GDG event and received information about the sector.",]}
            />
            <Resumecontent
              title="GDG Istanbul 2022"
              content={["I attended the GDG event and received information about the sector.",]}
            />
            <Resumecontent
              title="KTÜ Artifical Artificial Intelligence"
              content={[
                "I took part in team meetings",
                "I received an artificial intelligence certificate",
                "By attending artificial intelligence meetings, I learned how current artificial intelligence works and mathematical values.",
              ]}
            />
            <h3 className="resume-title">Ranks and Achivements</h3>
            <Resumecontent
              title=""
              content={["Computer Engineering 2.9"]}
            />
              <h3 className="resume-title">Education</h3>
            <Resumecontent
              title="Karadeniz Technical University"
              date="2016 - 2021"
              content={["Computer Engineering"]}
            />
            <Resumecontent
              title="Çayeli İMKB Science High School"
              date="2012 - 2016"
              content={[]}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Resume;
