import React from "react";
import GitHubCalendar from "react-github-calendar";
import { Row } from "react-bootstrap";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";

function Github() {
  const colourTheme = {
    background: "transparent",
    text: "#ffffff",
    grade4: "#ECFFDC",
    grade3: "#ECFFDC",
    grade2: "#ECFFDC",
    grade1: "#ECFFDC",
    grade0: "#ECFFDC",
  };
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
      <h1 className="project-heading" style={{ paddingBottom: "20px" }}>
        Days I <strong className="purple">Code</strong>
      </h1>
      <GitHubCalendar
        username="fatihkurcenli"
         blockSize={15}
        blockMargin={5}
        weekStart={1}
        colorScheme="dark"
        fontSize={16} 
      />
    </Row>
  );
}

export default Github;
