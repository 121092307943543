import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Tilt from "react-parallax-tilt";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Let me <span className="purple"> introduce </span> myself
            </h1>
            <p className="home-about-body">
              A highly motivated person, dynamic, persevering and rigorous.
              <br />

              <br /> I'm{" "}a <b className="purple">Computer Engineer</b> who is constantly improving myself,
              trying to develop both mobile and backend,
              and graduated from <b className="purple">Karadeniz Technical University</b> in July 2021.
              I'm developing myself as an <b className="purple">Native Android Developer </b>.
              <br />
              <br />
              <a className="purple" target="_blank"
                href="https://play.google.com/store/apps/dev?id=7649938272123083537&hl=tr&gl=TR"
                rel="noreferrer"> AutumnSun</a> is the name of a start-up company where I publish my mobile apps and develop on behalf of people, and I am also its founder.
              <br />
              <br />
              I like to open source my apps and share them on my <a className="purple" target="_blank"
                href="https://github.com/fatihkurcenli"
                rel="noreferrer"
              >Gihub</a> and be a team and achieve teamwork.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/fatihkurcenli"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <i className="fab fa-github"></i>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/KurcenliFatih"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/fatihkurcenli"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/fatihkurcenli"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container >
  );
}
export default Home2;